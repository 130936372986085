import QrCanvas from "@joyqr/qrcreator/dist/qr";
import { QrStateI } from "@joyqr/qrcreator/dist/qrstate";
import {
  useEffect, useRef,
  useState
} from "react";
import Frames from "../../qr-generator/frames";

interface Props {
  previewElementId: string;
  previewElementWidth: number;
  previewElementHeight: number;
  frames?: any[];
}

interface P extends Props {
  state: QrStateI;
  value: string;
}

export const createQrThumbnail = ({
  previewElementHeight,
  previewElementId,
  previewElementWidth,
  state,
  value,
}: P) => {
  const element = document.getElementById(previewElementId);
  if (element) {
    const q = new QrCanvas(
      previewElementWidth,
      previewElementWidth,
      previewElementId,
      null,
      Frames
    );
    q.renderQr(value, state as any);
    return q;
  }

  return null;
};

export const useNewQrGenerator = (
  {
    previewElementHeight,
    previewElementId,
    previewElementWidth,
    frames = [],
  }: Props,
  _initialState?: any
) => {
  const [QRVALUE, setQrValue] = useState("");
  const [isRendered, setIsRendered] = useState(false);
  const Generator = useRef<QrCanvas | null>(null);

  useEffect(() => {
    createGenerator();
  }, [isRendered, QRVALUE]);

  const createGenerator = (f?: boolean) => {
    const d = document.getElementById(previewElementId);

    if (d && !isRendered && !!QRVALUE) {
      Generator.current = new QrCanvas(
        previewElementWidth,
        previewElementWidth,
        previewElementId,
        null,
        Frames
      );
      Generator.current.renderQr(QRVALUE, _initialState);

      setIsRendered(true);
      return Generator.current;
    }

    if (d && isRendered) {
      Generator.current?.rerender();
    }

    return null;
  };

  return {
    value: QRVALUE,
    setValue: setQrValue,
    generator: Generator.current,
  };
};
